import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Canvas, useThree } from "@react-three/fiber";
import merge from "lodash.merge";
import ParticleField from "./ParticleField";
import initialConfig from "./config";
import { useTexture, Reflector, Stars, CameraShake } from "@react-three/drei";
import { EffectComposer, Bloom, Noise } from "@react-three/postprocessing";
import { Controls, CustomCamera } from "./ParticleField";
import { CameraHelper } from "three";

const cameraShakeConfig = {
  maxYaw: 0.01, // Max amount camera can yaw in either direction
  maxPitch: 0.01, // Max amount camera can pitch in either direction
  maxRoll: 0.01, // Max amount camera can roll in either direction
  yawFrequency: 0.1, // Frequency of the the yaw rotation
  pitchFrequency: 0.1, // Frequency of the pitch rotation
  rollFrequency: 0.1, // Frequency of the roll rotation
  intensity: 0.5, // initial intensity of the shake
  decay: false, // should the intensity decay over time
  decayRate: 0.65, // if decay = true this is the rate at which intensity will reduce at
  controls: undefined // if using orbit controls, pass a ref here so we can update the rotation
};

const Ground = props => {
  const floor = useTexture.preload("roughness.jpg");
  const normal = useTexture.preload("normal.jpg");
  return (
    <Reflector resolution={1024} args={[8, 8]} {...props}>
      {(Material, props) => (
        <Material color="#f0f0f0" metalness={0.2} roughnessMap={floor} normalMap={normal} normalScale={[2, 2]} {...props} />
      )}
    </Reflector>
  );
};

const CustomCanvas = props => {
  return (
    <>
      <p></p>

      <Canvas
        camera={{
          far: 100000,
          near: 1,
          fov: 35,
          position: [0, 1, 1750]
        }}
        gl={{
          antialias: false
        }}
      >
        <CustomCamera />
        <Controls />

        <ambientLight intensity={7} color={"red"} />

        <color attach="background" args={["black"]} />
        <Stars
          radius={6000} // Radius of the inner sphere (default=100)
          depth={100} // Depth of area where stars should fit (default=50)
          count={10000} // Amount of stars (default=5000)
          factor={100} // Size factor (default=4)
          saturation={0} // Saturation 0-1 (default=0)
          fade // Faded dots (default=false)
        />
        <ParticleField {...merge({}, initialConfig, props.config)} getPositionData={props.getPositionData} radius={props.radius} />
        <Ground
          scale={2000}
          mirror={1}
          blur={[500, 100]}
          mixBlur={12}
          mixStrength={1.5}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          position-y={-props.radius}
        />
        <EffectComposer multisampling={8}>
          <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.2} intensity={1} />
          <Bloom luminanceThreshold={0} luminanceSmoothing={0.2} intensity={0.1} />
        </EffectComposer>
      </Canvas>
    </>
  );
};

/**
 * Creates a 2D/3D particle field with react-three-fiber, three.js and WebGL
 *
 * Documentation on the configuration object can be found in the github repo
 * @see https://github.com/tim-soft/react-particles-webgl
 *
 * For a real-time configuration generator and various demos
 * @see https://timellenberger.com/particles
 */
const ParticleCanvas = ({ config, getPData, radius }) => {
  const [clientSide, setClientSide] = useState(false);

  const getPositionData = data => {
    getPData(data);
  };

  useEffect(() => {
    setClientSide(true);
  }, []);

  if (!clientSide) return null;

  return <CustomCanvas config={config} getPositionData={getPositionData} radius={radius} />;
};

ParticleCanvas.propTypes = {
  config: PropTypes.object
};

ParticleCanvas.defaultProps = {
  config: {}
};

export default ParticleCanvas;

export const defaultConfig = { ...initialConfig };
