import React, { useState } from "react";
import { start } from "tone";
import Application from "./application";

import { Alert, AlertTitle, Button } from "@mui/material";

export default function Startup() {
  const [startApplication, setStartApplication] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);

  const handleApplication = () => {
    setStartApplication(!startApplication);
  };

  const handleDisclaimer = () => {
    setDisclaimer(!disclaimer);
  };
  return (
    <div className="App">
      {!disclaimer && (
        <Alert onClose={handleDisclaimer} severity="error">
          <AlertTitle>
            <strong>ATTENTION: </strong>
            loud noises ahead, tune down volume
          </AlertTitle>
        </Alert>
      )}
      {!startApplication && (
        <div className="centerStartMenu">
          <h1 style={{ color: "white", fontSize: 60, marginBottom: "30px" }}>
            p a r t i c l e s{" "}
          </h1>
          <Button
            disabled={disclaimer ? false : true}
            color={"error"}
            variant="contained"
            onClick={handleApplication}
          >
            START
          </Button>
        </div>
      )}
      {startApplication && (
        <Application backToStartupHandler={handleApplication} />
      )}
    </div>
  );
}
