/* eslint-disable no-shadow */
import React, { useRef, useMemo, forwardRef, useImperativeHandle, useEffect } from "react";
import * as THREE from "three";
import PropTypes from "prop-types";
import { AdditiveBlending, Camera } from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import animate from "./lib/animate";
import computeLines from "./lib/computeLines";
import computeParticles from "./lib/computeParticles";

// Default Cube dimensions

export const CustomCamera = () => {
  const size = useThree();

  // Default distance from camera to particle field
  const distToParticles = 4000;
  const aspectRatio = size.width / size.height;

  // Calculates the proper FOV for 2D particle field to
  // perfectly fill canvas
  const cameraFOV = 2 * Math.atan(size.width / aspectRatio / (2 * distToParticles)) * (180 / Math.PI);
  const position = [0, 1, distToParticles];

  return <PerspectiveCamera far={10000} near={1} aspect={aspectRatio} fov={cameraFOV} position={position} />;
};
/**
 * Creates a particle cloud with various config options
 */
const ParticleField = ({ particles, lines, direction, showCube, dimension, velocity, boundaryType, getPositionData, radius }) => {
  const animation = useRef(0);
  const group = useRef();
  const devicePixelRatio = window.devicePixelRatio.toFixed(1);
  const { size, camera } = useThree();

  // global mouse / touch coordinates for interaction

  let mouseX = 0;
  let mouseY = 0;

  const calcMousePosition = () => {
    const vec = new THREE.Vector3();
    const pos = new THREE.Vector3();

    vec.set((mouseX / window.innerWidth) * 2 - 1, -(mouseY / innerHeight) * 2 + 1, 0.5);
    vec.unproject(camera);
    vec.sub(camera.position).normalize();

    const distance = -camera.position.z / vec.z;

    pos.copy(camera.position).add(vec.multiplyScalar(distance));
    return pos;
  };

  // Compute lines between points
  const [lineMeshGeometry, lineMeshMaterial, linePositions, lineColors] = useMemo(() => computeLines({ particles, lines }), [
    particles,
    lines
  ]);

  // Compute point cloud
  const [pointCloudGeometry, pointMaterial, particlesData, particlePositions, bounds] = useMemo(
    () =>
      computeParticles({
        particles,
        dimension,
        devicePixelRatio,
        direction,
        size,
        radius,
        velocity
      }),
    [particles, dimension, direction, devicePixelRatio, size, velocity]
  );

  // Assign state to animation ref
  // This object is passed to Animation.js in render loop
  animation.current = {
    minDistance: lines.minDistance,
    limitConnections: lines.limitConnections,
    maxConnections: lines.maxConnections,
    particleCount: particles.count,
    bounds,
    lineMeshGeometry,
    pointCloudGeometry,
    particlesData,
    particlePositions,
    linePositions,
    lineColors,
    showLines: lines.visible,
    boundaryType,
    mouseVector: calcMousePosition()
  };

  const handleMouseMove = ev => {
    mouseX = ev.clientX;
    mouseY = ev.clientY;
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
  });

  // Direct access to render loop, executes on each frame
  // State changes must be passed into hook via refs
  // useRender() contents are called in a requestAnimationFrame()
  useFrame(() => {
    getPositionData({
      particles: animation.current.pointCloudGeometry.getAttribute("position"),
      bounds: animation.current.bounds
    });
    // Animate current state of particles + lines
    animation.current.mouseVector = calcMousePosition();

    animate(animation.current);
  });

  return (
    <scene>
      <group ref={group}>
        {/* Bounding box that particles exist inside of */}
        {showCube && (
          <boxHelper>
            <mesh name="object">
              <meshBasicMaterial attach="material" color="white" blending={AdditiveBlending} wireframe transparent />
              <boxBufferGeometry attach="geometry" args={[radius, radius, radius]} />
            </mesh>
          </boxHelper>
        )}

        {/* Lines connecting particles */}
        {lines.visible && <lineSegments geometry={lineMeshGeometry} material={lineMeshMaterial} />}
        {/* Lines connecting particles */}

        {/* Particles */}
        {particles.visible && <points geometry={pointCloudGeometry} material={pointMaterial} />}
      </group>
    </scene>
  );
};
export const Controls = () => {
  const {
    camera,
    gl: { domElement }
  } = useThree();
  return <OrbitControls args={[camera, domElement]} />;
};
ParticleField.propTypes = {
  showCube: PropTypes.bool.isRequired,
  dimension: PropTypes.oneOf(["2D", "3D"]).isRequired,
  boundaryType: PropTypes.oneOf(["bounce", "passthru"]).isRequired,
  velocity: PropTypes.number.isRequired,
  direction: PropTypes.shape({
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
    zMin: PropTypes.number,
    zMax: PropTypes.number
  }).isRequired,
  lines: PropTypes.shape({
    colorMode: PropTypes.oneOf(["rainbow", "solid"]),
    color: PropTypes.string,
    transparency: PropTypes.number,
    maxConnections: PropTypes.number,
    limitConnections: PropTypes.bool,
    minDistance: PropTypes.number,
    visible: PropTypes.bool
  }).isRequired,
  particles: PropTypes.shape({
    count: PropTypes.number,
    minSize: PropTypes.number,
    maxSize: PropTypes.number,
    boundingBox: PropTypes.oneOf(["canvas", "cube"]),
    shape: PropTypes.oneOf(["circle", "square"]),
    colorMode: PropTypes.oneOf(["rainbow", "solid"]),
    color: PropTypes.string,
    transparency: PropTypes.number,
    visible: PropTypes.bool
  }).isRequired,
  cameraControls: PropTypes.shape({
    enabled: PropTypes.bool,
    enableDamping: PropTypes.bool,
    dampingFactor: PropTypes.number,
    enableZoom: PropTypes.bool,
    autoRotate: PropTypes.bool,
    autoRotateSpeed: PropTypes.number,
    resetCameraFlag: PropTypes.bool
  }).isRequired
};

export default ParticleField;
