import "./App.css";
import React from "react";
import * as Tone from "tone";
import StartAudioContext from "startaudiocontext";
import { useRef } from "react";
import ParticleCanvas from "@particles/react-particles-webgl";
import Button from "@mui/material/Button";

const config = {
  showCube: false,
  dimension: "3D", // do not change
  velocity: 3, // own random velocity of particles
  boundaryType: "bounce", // do not change
  antialias: false,
  direction: {
    xMin: -0.2,
    xMax: 0.2,
    yMin: 0,
    yMax: 0.2,
    zMin: -0.2,
    zMax: 0.2
  },
  lines: {
    colorMode: "solid",
    color: "#white",
    transparency: 0.9,
    limitConnections: false,
    visible: false // activate lines
  },
  particles: {
    colorMode: "solid",
    color: "#white", // color will stay white, as set by lighting material
    transparency: 0.8,
    shape: "circle", // or "cube"
    boundingBox: "cube",
    count: 20,
    minSize: 50,
    maxSize: 100,
    visible: true
  }
};
// helper
const map = (value, x1, y1, x2, y2) => {
  return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
};

const count = config.particles.count;
let allOscillators = [];
let panners = [];

function createOscillators() {
  for (let i = 0; i < count; i += 1) {
    const panner = new Tone.Panner().connect(Tone.getDestination());
    panner.toDestination();
    panners.push(panner);
    const osc = new Tone.Oscillator(100, "sine").connect(panner).start();
    osc.set({ volume: -25 });
    allOscillators.push(osc);
  }
}

let positions;
let bounds;

const getPData = data => {
  const context = Tone.getContext();
  const now = context.currentTime;

  bounds = data.bounds;
  positions = data.particles.array;

  //console.log(bounds);

  for (let i = 0; i < count; i += 1) {
    const x = positions[i * 3];
    const y = positions[i * 3 + 1];
    const z = positions[i * 3 + 2];
    const vol = calcVolume(y);

    const freq = calcFreq(x);

    const pan = calcPan(z);
    //console.log(allOscillators);
    allOscillators[i].frequency.setValueAtTime(freq, now);
    allOscillators[i].volume.setValueAtTime(vol, now);

    panners[i].pan.value = pan;
  }
};

const calcPan = val => {
  let bound = bounds.zBounds / 2;
  let pan = map(val, -bound, bound, -1, 1);
  if (pan > 1) pan = 1;
  if (pan < -1) pan = -1;
  return pan;
};

const calcVolume = val => {
  let bound = bounds.yBounds / 2;
  let phase = val;
  return (phase = map(phase, -bound, bound, -50, -15));
};
const calcFreq = val => {
  let bound = bounds.yBounds / 2;
  let freq = val;
  //onsole.log(freq);
  return (freq = map(freq, -bound, bound * 2, 30, 500));
};

export default function Application(props) {
  allOscillators = [];
  panners = [];

  const radius = window.innerWidth;

  Tone.setContext(new Tone.Context(new Tone.context.rawContext.constructor({ sampleRate: 44100 })));
  const context = Tone.getContext();
  StartAudioContext(context);

  createOscillators();

  console.log("audio is ready");

  function switchOsc() {
    let oscType;
    const type = allOscillators[0].type;
    console.log("osc type: ", type);
    for (let i = 0; i < count; i += 1) {
      if (type === "sine") {
        oscType = "sawtooth";
        allOscillators[i].set({ type: oscType });
      } else if (type === "sawtooth") {
        oscType = "sine";
        allOscillators[i].set({ type: oscType });
      }
    }
  }

  function stop() {
    console.log("stop");
    const context = Tone.getContext();
    context.close();
    props.backToStartupHandler();
  }

  return (
    <div className="App">
      <ParticleCanvas config={config} getPData={getPData} radius={radius} />
      <div style={{ position: "absolute", bottom: "10em", left: 0, right: 0 }}>
        <Button color="error" variant="outlined" onClick={stop}>
          back to Menu
        </Button>

        <Button color="error" variant="outlined" onClick={switchOsc}>
          Switch OSC type
        </Button>
      </div>
    </div>
  );
}
